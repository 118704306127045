import { FileDownload } from '@shared/http';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { meetingSchema } from '@/features/calendar/types/meeting';
import { artiaProtectedApi } from '@/lib/api-client';
import { QueryConfig } from '@/lib/react-query';
import { buildQueryString } from '@/utils/filtering-functions';

const queryKeys = {
  all: ['calendar'],
  list: (filters: GetCalenderFilters) => [...queryKeys.all, { ...filters }],
};

// get calender
type GetCalenderFilters = {
  month: string;
  tracked?: boolean;
  priority?: boolean;
};

const meetingsSchema = z.array(meetingSchema);

const fetchCalendar = async (filters: GetCalenderFilters) => {
  const data = await artiaProtectedApi.get(`calendar${buildQueryString(filters)}`);

  return meetingsSchema.parse(data);
};

const calendarQueryOptions = (filters: GetCalenderFilters) => {
  return queryOptions({
    queryKey: [...queryKeys.list(filters)],
    queryFn: () => fetchCalendar(filters)
  });
};

type UseCalendarQueryOptions = {
  filters: GetCalenderFilters;
  queryConfig?: QueryConfig<typeof calendarQueryOptions>
};

export const useCalendarQuery = ({ filters, queryConfig }: UseCalendarQueryOptions) => {
  return useQuery({
    ...calendarQueryOptions(filters),
    ...queryConfig
  });
};

export const downloadCalendarFile = async (id: number | string): Promise<FileDownload> => {
  const response = await artiaProtectedApi.get(`calendar/${id}/invite.ics`, {
    responseType: 'blob'
  });

  if (response === undefined || response === null) {
    throw new Error(`Unable to download calendar file for meeting ${id}.`);
  }

  const blob = response.data as unknown as Blob;

  return {
    contents: blob,
    contentType: blob.type,
    filename: 'invite.ics'
  };
};
