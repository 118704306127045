import { ProtectedRoute } from '@shared/auth';
import { useFeatures } from '@shared/feature-flags';
import { useQueryClient } from '@tanstack/react-query';
import {
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  GroupingState,
  SortingState
} from '@tanstack/react-table';
import { isEmpty, isNil, parseInt } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LiaFolderOpen } from 'react-icons/lia';

import Nullish from '@/components/nullish';
import { Button } from '@/components-new/button';
import { DataTable } from '@/components-new/data-table';
import { DrugName } from '@/components-new/drugs';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { SectionDescription, SectionHeading } from '@/components-new/section';
import { useDrug } from '@/features/drugs/api/use-drug';
import {
  exportDrugCoveragesToCsv,
  makeDrugCoveragesQueryKey,
  useDrugCoverages
} from '@/features/drugs/api/use-drug-coverages';
import { CoverageDetailsDrawer } from '@/features/drugs/components/coverage-details-drawer';
import { PdlStateCoverageChart } from '@/features/drugs/components/pdl-state-coverage-chart';
import { PdlStatusBadge } from '@/features/drugs/components/pdl-status-badge';
import { getSortableCoverageTag } from '@/features/drugs/types/coverage-tag';
import { DrugDisplayable } from '@/features/drugs/types/drug';
import { DrugCoverage } from '@/features/drugs/types/drug-coverage';
import { dismissNotification, notifyError } from '@/lib/notification/notifications';
import { useTable } from '@/lib/react-table';
import { useParams } from '@/router';
import { insertIf } from '@/utils/arrays';
import { drugName } from '@/utils/drugs';
import { stepTherapyDisplay } from '@/utils/state-coverage';

const columnHelper = createColumnHelper<DrugCoverage>();

/**
 * Cell display for the coverages prior authorization type.
 */
const PaTypesCell = ({ coverage }: { coverage: DrugCoverage }) => {
  const {
    paTypes
  } = coverage;

  if (!paTypes || paTypes.length === 0) return <Nullish />;

  return paTypes.map(paType => paType.label).join('; ');
};

/**
 * Cell display for the coverages state name.
 */
const StateCell = ({ state, isGroupParent }: { state: string, isGroupParent: boolean }) => {
  // if the row is the groups parent a.k.a the first row in the group, then always display the state name
  if (isGroupParent) {
    return <span>{state}</span>;
  }

  // only display the state name on hover
  return <span className="opacity-0 transition-all group-hover/table-row:opacity-100">{state}</span>;
};

/**
 * Cell display for the coverage tags or the drug name if no coverage tags are applied.
 */
const CoverageTagsCell = ({ coverage, drug }: {  coverage: DrugCoverage, drug: DrugDisplayable }) => {
  const tags = coverage?.coverageTags?.filter(tag => !tag.isDefault)
    .map(tag => getSortableCoverageTag(tag))
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map(sortableTag => sortableTag.coverageTag);

  return (
    <div className="flex flex-row gap-1">
      <DrugName drug={drug} />
      <span className="text-gray-500">
        {tags?.map((tag) =>
          // @ts-ignore
          tag.type == 'Indication' ? `for ${tag.name}` : tag.name
        ).join(', ')}
        </span>
    </div>
  );
};

const DrugCoveragePage = () => {
  const { id } = useParams('/drugs/:id');
  const { data: drug } = useDrug(id);
  const {
    data: coverages,
    isLoading: isLoadingStateCoverages,
    isError: isErrorStateCoverages
  } = useDrugCoverages({ drugId: parseInt(id) });
  const [coverageDetailsForReview, setCoverageDetailsForReview] = useState<DrugCoverage | undefined>();
  const queryClient = useQueryClient();

  const { getFeature } = useFeatures();
  const isStepTherapyEnabled = getFeature('clientViewStepTherapy');

  const handleRetry = useCallback(async () => {
    await queryClient.resetQueries({ queryKey: makeDrugCoveragesQueryKey(parseInt(id)) });
  }, [id, queryClient]);

  const [sorting, setSorting] = useState<SortingState>([{ id: 'state', desc: false }]);
  const [grouping, setGrouping] = useState<GroupingState>(['state']);

  // display error notification with support to retry on error
  useEffect(() => {
    if (isErrorStateCoverages) {
      const id = notifyError({
        message: 'We were unable to load coverage information at this time.',
        actions: (
          <Button
            plain
            color="secondary"
            onClick={() => {
              void handleRetry();
              dismissNotification(id);
            }}
          >
            Retry
          </Button>
        )
      });
    }
  }, [handleRetry, isErrorStateCoverages]);

  const columns = useMemo(() => [
    columnHelper.accessor(
      (row) => row.state.name,
      {
        id: 'state',
        header: 'State',
        enableSorting: false,
        cell: ({ cell, row }) => {
          // determine if the row is the first in the group
          const parentRow = row.getParentRow();
          const isFirstRowInGroup = parentRow?.getLeafRows().findIndex(leaf => leaf.id === row.id) === 0;

          return (
            <StateCell state={cell.getValue()} isGroupParent={isFirstRowInGroup} />
          );
        },
        meta: {
          cellProps: ({ row }) => {
            const parentRow = row.getParentRow();
            const groupCount = parentRow?.getLeafRows().length ?? 0;
            const rowPosition = parentRow?.getLeafRows().findIndex(leaf => leaf.id === row.id);
            const isLastRow = rowPosition === groupCount - 1;

            return {
              bordered: isLastRow,
            };
          }
        }
      }
    ),
    columnHelper.accessor('coverageTags', {
      header: 'Applies To',
      cell: ({ row }) => {
        return <CoverageTagsCell drug={drug} coverage={row.original} />;
      },
      enableSorting: false,
      enableGrouping: false,
    }),
    columnHelper.accessor('pdlStatus', {
      header: 'PDL Status',
      cell: info => <PdlStatusBadge pdlStatus={info.getValue()}/>,
      enableSorting: false,
      enableGrouping: false,
    }),
    columnHelper.accessor('paTypes', {
      header: 'PA Type',
      cell: ({ row }) => <PaTypesCell coverage={row.original} />,
      enableSorting: false,
      enableGrouping: false,
    }),
    ...insertIf(isStepTherapyEnabled, columnHelper.accessor('stepTherapyCount', {
      header: 'Step Therapy',
      cell: ({ row }) => (
        <Nullish
          value={stepTherapyDisplay(row.original.stepTherapyCount, row.original.hasAdditionalSteps)}
        />
      ),
      enableSorting: false,
      enableGrouping: false,
    })),
    columnHelper.display({
      id: 'actions',
      header: () => (
        <span className="sr-only">Actions</span>
      ),
      cell: ({ row }) => (
        <div className="flex justify-end">
          <Button
            outline
            onClick={() => setCoverageDetailsForReview(row.original)}>
            Details
          </Button>
        </div>
      ),
      enableSorting: false
    })
  ], [isStepTherapyEnabled, drug]);

  const table = useTable({
    data: coverages ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableSorting: true,
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    state: {
      sorting,
      grouping
    },
    initialState: {
      expanded: true
    }
  });

  const showEmptyTableEmpty = !isLoadingStateCoverages && (isEmpty(coverages) || isNil(coverages));
  const drugDisplayName = drugName(drug);

  return (
    <div className="mt-4">
      <div className="grid gap-16 md:grid-cols-2">
        <div className="flex flex-col">
          <div>
            <SectionHeading level={2}>
              PDL Status Overview
            </SectionHeading>
            <SectionDescription>
              PDL Status of {drugDisplayName} within each state.
            </SectionDescription>
          </div>
          <PdlStateCoverageChart drug={drug} coverages={coverages} isLoading={isLoadingStateCoverages} />
        </div>
      </div>
      <div className="mt-4">
        <div className="sm:flex-auto flex">
          <div className="basis-3/4">
            <SectionHeading level={2}>Coverage</SectionHeading>
            <SectionDescription>
              How each state covers {drugDisplayName} and their prior authorization requirements.
            </SectionDescription>
          </div>
          <div className="basis-1/4">
            <Button className="float-right m-1" onClick={() => exportDrugCoveragesToCsv(parseInt(id))}>Export</Button>
          </div>
        </div>
        <div className="relative mt-8 flow-root">

          <DataTable
            tableBodyRowProps={() => ({
              className: 'group/table-row hover:bg-zinc-50'
            })}
            table={table}
            placeholder={() => (
              <div className="relative flex h-48 items-center justify-center">
                {isLoadingStateCoverages && (
                  <Overlay>
                    <Loader message="Loading coverage..." />
                  </Overlay>
                )}
                {showEmptyTableEmpty && (
                  <EmptyState>
                    <LiaFolderOpen className="size-12 text-gray-500"/>
                    <EmptyStateHeading>No coverage</EmptyStateHeading>
                    <EmptyStateBody>
                      We're working to bring you the latest coverage information.
                    </EmptyStateBody>
                  </EmptyState>
                )}
              </div>
            )}
          />
        </div>
        <CoverageDetailsDrawer
          coverage={coverageDetailsForReview}
          onClose={() => setCoverageDetailsForReview(undefined)}
        />
      </div>
    </div>
  );
};

const DrugCoveragePageContainer = () => {
  return (
    <ProtectedRoute>
      <DrugCoveragePage/>
    </ProtectedRoute>
  );
};

export default DrugCoveragePageContainer;
