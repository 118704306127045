import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect } from 'react';
import {
  LiaCalendar,
  LiaExternalLinkAltSolid,
  LiaPillsSolid,
  LiaTagsSolid,
  LiaTimesSolid,
  LiaVideoSolid
} from 'react-icons/lia';

import Nullish from '@/components/nullish';
import { Badge } from '@/components-new/badge';
import { Button } from '@/components-new/button';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '@/components-new/description-list';
import { Drawer, DrawerBody, DrawerHeader, DrawerTitle } from '@/components-new/drawer';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { SectionHeading } from '@/components-new/section';
import { TextLink } from '@/components-new/text';
import { downloadCalendarFile } from '@/features/calendar/api';
import {
  formatMeetingFormat,
  Meeting,
  parseMeetingDateTime
} from '@/features/calendar/types/meeting';
import { useDelayedClose } from '@/hooks/use-delayed-close';
import { isValidUrl } from '@/utils/urls';

type MeetingListProps = {
  meetings?: Meeting[];
  isLoading: boolean;
  emptyState?: React.ReactNode;
  onShowMeetingDetails: MeetingCardProps['showMeetingDetails'];
} & Omit<React.ComponentPropsWithoutRef<'ul'>, 'children'>;

export const MeetingsList = ({ meetings, isLoading, emptyState, onShowMeetingDetails, className, ...props }: MeetingListProps) => {
  const hasMeetings = !isEmpty(meetings) && !isNil(meetings);
  const showLoader = isLoading;
  const showEmptyState = !hasMeetings && !showLoader;
  const showMeetings = !showLoader && hasMeetings;

  return (
    <>
      {showLoader && (
        <Overlay>
          <Loader message="Loading meetings..."/>
        </Overlay>
      )}
      {
        showEmptyState && (emptyState ? (
          <>{emptyState}</>
        ) : (
          <EmptyState>
            <LiaCalendar className="size-12 text-gray-500"/>
            <EmptyStateHeading>No meetings</EmptyStateHeading>
            <EmptyStateBody>
              There are no meetings for this month. Use the calendar to adjust your search.
            </EmptyStateBody>
          </EmptyState>
        ))
      }
      {showMeetings && (
        <ol className={clsx('divide-y divide-gray-100 text-sm leading-6', className)} {...props}>
          {meetings.map((meeting) => (
            <MeetingCard key={meeting.id} meeting={meeting} showMeetingDetails={onShowMeetingDetails}/>
          ))}
        </ol>
      )}
    </>
  );
};

type MeetingCardProps = {
  meeting: Meeting;
  showMeetingDetails: (meeting: Meeting) => void;
};

export const MeetingCard = ({ meeting, showMeetingDetails }: MeetingCardProps) => {
  const when = parseMeetingDateTime(meeting);
  const trackedDrugs = meeting.drugs.filter(drug => drug.isTracked);
  const trackedClassifications = meeting.classifications.filter(classification => classification.isTracked);

  return (
    <li key={meeting.id} className="flex items-center justify-between gap-x-6 py-5">
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <p className="text-base font-semibold leading-6 text-gray-900"><MeetingName meeting={meeting}/></p>
          <Badge color="secondary">{formatMeetingFormat(meeting.format)}</Badge>
        </div>
        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
          <dl className="flex flex-row gap-2">
            <dt className="mt-0.5">
              <span className="sr-only">When</span>
              <LiaCalendar className="size-5 text-gray-400" aria-hidden="true"/>
            </dt>
            <dd>
              {when.date}, {when.startTime}
            </dd>

            {trackedDrugs.length > 0 && (
              <>
                <dt className="mt-0.5">
                  <span className="sr-only">Drugs</span>
                  <LiaPillsSolid className="size-5 text-gray-400" aria-hidden="true" title="Single Drug Review"/>
                </dt>
                <dd>
                  {trackedDrugs.map(drug => drug.label).join(', ')}
                </dd>
              </>
            )}

            {!isEmpty(trackedClassifications) && (
              <>
                <dt className="mt-0.5">
                  <span className="sr-only">Drug Classes</span>
                  <LiaTagsSolid className="size-5 text-gray-400" aria-hidden="true" title="Class Review"/>
                </dt>
                <dd>
                  {trackedClassifications.map(classification => classification.label).join(', ')}
                </dd>
              </>
            )}
          </dl>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <Button outline onClick={() => showMeetingDetails(meeting)}>
          View Details<span className="sr-only">, <MeetingName meeting={meeting}/></span>
        </Button>
      </div>
    </li>
  );
};

type MeetingDetailsDrawerProps = {
  meeting?: Meeting;
  onClose: () => void;
}

export const MeetingDetailsDrawer = ({ meeting, onClose }: MeetingDetailsDrawerProps) => {
  const { isOpen, handleOpen, handleClose } = useDelayedClose(!!meeting, onClose);

  useEffect(() => {
    if (meeting) {
      handleOpen();
    }
  }, [meeting, handleOpen]);

  if (meeting === undefined) {
    return (
      <Drawer open={isOpen} onClose={handleClose} size="xl">
      </Drawer>
    );
  }

  const when = parseMeetingDateTime(meeting);

  const onDownload = async (id: number) => {
    const calendarFile = await downloadCalendarFile(id);

    try {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(calendarFile.contents);
      link.download = calendarFile.filename;
      link.click();
    } catch (ex) {
      console.error(ex);
      alert(ex);
    }
  };

  return (
    <Drawer open={isOpen} onClose={handleClose} size="xl">
      <DrawerHeader>
        <DrawerTitle><MeetingName meeting={meeting}/></DrawerTitle>
        <Button plain onClick={handleClose}>
          <LiaTimesSolid/>
        </Button>
      </DrawerHeader>
      <DrawerBody>
        <div className="space-y-6">
          <Button
            color="secondary"
            onClick={() => onDownload(meeting.id)}
          >Add to Calendar</Button>

          <div>
            <SectionHeading level={3}>Information</SectionHeading>
            <DescriptionList>
              <DescriptionTerm>Date</DescriptionTerm>
              <DescriptionDetails>{when.date}</DescriptionDetails>

              <DescriptionTerm>Time</DescriptionTerm>
              <DescriptionDetails>{when.startTime}&ndash;{when.endTime}</DescriptionDetails>

              <DescriptionTerm>Format</DescriptionTerm>
              <DescriptionDetails>{formatMeetingFormat(meeting.format)}</DescriptionDetails>
            </DescriptionList>
          </div>

          {meeting.location && (
            <div>
              <SectionHeading>Location</SectionHeading>
              <DescriptionList>
                <DescriptionTerm>Venue</DescriptionTerm>
                <DescriptionDetails>
                  {meeting.location.venue}
                </DescriptionDetails>

                {meeting.location.room && (
                  <>
                    <DescriptionTerm>Room</DescriptionTerm>
                    <DescriptionDetails>{meeting.location.room}</DescriptionDetails>
                  </>
                )}

                {meeting.location.address && (
                  <>
                    <DescriptionTerm>Address</DescriptionTerm>
                    <DescriptionDetails>
                      {meeting.location.address.line1}<br/>
                      {meeting.location.address.line2 && (
                        <>
                          {meeting.location.address.line2}<br/>
                        </>
                      )}
                      {meeting.location.address.city}, {meeting.location.address.state} {meeting.location.address.zipcode}
                    </DescriptionDetails>
                  </>
                )}
              </DescriptionList>
            </div>
          )}

          {meeting.webinar && (
            <div>
              <SectionHeading>Webinar</SectionHeading>
              <DescriptionList>
                {meeting.webinar.url && isValidUrl(meeting.webinar.url) && (
                  <>
                    <DescriptionTerm>
                      <LiaVideoSolid
                        aria-hidden="true"
                        className="mr-2 inline h-6 w-5 text-gray-400"
                      /> {new URL(meeting.webinar.url).hostname}
                    </DescriptionTerm>
                    <DescriptionDetails>
                      <Button color="secondary" href={meeting.webinar.url}>Join</Button>
                    </DescriptionDetails>
                  </>
                )}

                <DescriptionTerm>Webinar Phone</DescriptionTerm>
                <DescriptionDetails>
                  <Nullish value={meeting.webinar.phone}/>
                </DescriptionDetails>

                <DescriptionTerm>Webinar Meeting ID</DescriptionTerm>
                <DescriptionDetails>
                  <Nullish value={meeting.webinar.meetingId}/>
                </DescriptionDetails>

                <DescriptionTerm>Webinar Password</DescriptionTerm>
                <DescriptionDetails>
                  <Nullish value={meeting.webinar.password}/>
                </DescriptionDetails>
              </DescriptionList>
            </div>
          )}

          <div>
            <SectionHeading>Details</SectionHeading>
            <DescriptionList>
              <DescriptionTerm>Type</DescriptionTerm>
              <DescriptionDetails>{meeting.type.label} ({meeting.type.code})</DescriptionDetails>

              <DescriptionTerm>Drugs</DescriptionTerm>
              <DescriptionDetails>
                <Nullish
                  value={meeting.drugs}
                  valueRenderer={(drugs) => drugs.map(drug => drug.label).join(', ')}
                />
              </DescriptionDetails>

              <DescriptionTerm>Drug Classes</DescriptionTerm>
              <DescriptionDetails>
                <Nullish
                  value={meeting.classifications}
                  valueRenderer={(classifications) => classifications.map(classification => classification.label).join(', ')}
                />
              </DescriptionDetails>

              <DescriptionTerm>Board Members</DescriptionTerm>
              <DescriptionDetails>
                <Nullish value={meeting.boardMembers}/>
              </DescriptionDetails>
            </DescriptionList>
          </div>

          {(meeting.resources && (meeting.resources.agendaLink || meeting.resources.meetingMinutesLink || meeting.resources.recordingLink || meeting.resources.speakerPoliciesLink)) && (
            <div>
              <SectionHeading className="mb-3">Resources</SectionHeading>
              {meeting.resources.agendaLink && isValidUrl(meeting.resources.agendaLink) && <MeetingResourceItem url={meeting.resources.agendaLink} name="Agenda"/>}
              {meeting.resources.meetingMinutesLink && isValidUrl(meeting.resources.meetingMinutesLink) && <MeetingResourceItem url={meeting.resources.meetingMinutesLink} name="Meeting Minutes"/>}
              {meeting.resources.recordingLink && isValidUrl(meeting.resources.recordingLink) && <MeetingResourceItem url={meeting.resources.recordingLink} name="Recording"/>}
              {meeting.resources.speakerPoliciesLink && isValidUrl(meeting.resources.speakerPoliciesLink) && <MeetingResourceItem url={meeting.resources.speakerPoliciesLink} name="Speaker Policies"/>}
            </div>
          )}
        </div>
      </DrawerBody>
    </Drawer>
  );
};

const MeetingResourceItem = ({ url, name }: { url: string, name: string }) => {
  return (
    <div className="mt-2 flex w-full flex-none gap-x-2">
      <dt className="flex-none">
        <LiaExternalLinkAltSolid aria-hidden="true" className="h-6 w-5 text-gray-400"/>
      </dt>
      <dd className="text-sm leading-6 text-gray-500">
        <TextLink href={url}>{name}</TextLink>
      </dd>
    </div>
  );
};

const MeetingName = ({ meeting }: { meeting: Meeting }) => {
  return <>{meeting.state.label} &mdash; {meeting.type.code} Meeting</>;
};
