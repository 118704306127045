import { PaTypeCell } from '@shared/pa-info/pa-type-cell';
import { Box, DataTable, Text } from 'grommet';
import React from 'react';
import { HiOutlinePencil } from 'react-icons/hi2';

import { TextLink } from '@/components-new/text';
import {
  NetCostComparisonClinicalsProduct,
  NetCostComparisonRow,
  NetCostComparisonRowExpansionArgs, PackagingNetCostComparison,
  SUPPRESSABLE_PROPERTIES
} from '@/features/bid-analysis/types/net-cost-comparison';
import { PdlStatusCell } from '@/features/coverage/components/index/pdl-status-cell';

import { useBidAnalysisDetails } from '../../../bid-analysis-details.provider';
import { CurrencyCell } from './currency-cell';
import { NumericCell } from './numeric-cell';
import { PercentCell } from './percent-cell';
import { PharmacyReimbursementUnitCell } from './pharmacy-reimbursement-unit-cell';
import { ProductCell } from './product-cell';

type NetCostComparisonTableProps = {
  rows: NetCostComparisonRow[];
  bidAnalysisType: 'state' | 'pool';
  onSelectCoverage: (coverage: NetCostComparisonClinicalsProduct, row: NetCostComparisonRow) => void;
  isRowExpanded: (args: NetCostComparisonRowExpansionArgs) => boolean;
  onRowExpand: (args: NetCostComparisonRowExpansionArgs) => void;
  onRowCollapse: (args: NetCostComparisonRowExpansionArgs) => void;
  onRowUpdate: (row: NetCostComparisonRow, ndc: PackagingNetCostComparison) => void;
};

export const NetCostComparisonTable = ({ rows, bidAnalysisType, onSelectCoverage, isRowExpanded, onRowExpand, onRowCollapse, onRowUpdate }: NetCostComparisonTableProps) => {
  const { bidAnalysis } = useBidAnalysisDetails();

  const buildRowProps = (netCostComparisonRows: NetCostComparisonRow[]) => netCostComparisonRows.reduce((
    previous,
    { rowType, key }
  ) => {
    switch (rowType) {
      case 'state-total':
        return {
          ...previous,
          [key]: { background: ['light-4'] }
        };
      case 'grand-total':
        return {
          ...previous,
          [key]: { background: ['light-4'] }
        };
      default:
        return previous;
    }
  }, {});

  return (
    <div style={{ overflow: 'auto', height: '90vh' }}>
      <DataTable
        columns={[
          {
            property: 'drug',
            header: 'Product',
            pin: true,
            render: (row) => (
              <ProductCell
                row={row}
                isRowExpanded={isRowExpanded}
                onRowExpand={onRowExpand}
                onRowCollapse={onRowCollapse}
              />
            )
          },
          {
            property: 'units',
            header: 'Units',
            align: 'end',
            render: (row) => <NumericCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.unitsReimbursed}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              singleState={bidAnalysisType === 'state'}
              isSuppressable={SUPPRESSABLE_PROPERTIES.includes('unitsReimbursed')}
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              isSuppressed={row.isSuppressionUsed || (row.rowType === 'drug' && row.suppressedValues.includes('unitsReimbursed'))}
              showTotal
            />
          },
          {
            property: 'scripts',
            header: 'Scripts',
            align: 'end',
            render: (row) => <NumericCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.numberOfPrescriptions}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              singleState={bidAnalysisType === 'state'}
              isSuppressable={SUPPRESSABLE_PROPERTIES.includes('numberOfPrescriptions')}
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              isSuppressed={row.isSuppressionUsed || (row.rowType === 'drug' && row.suppressedValues.includes('numberOfPrescriptions'))}
              showTotal
            />
          },
          {
            property: 'unitsPerRx',
            header: 'Units/Rx',
            align: 'end',
            render: (row) => <NumericCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.unitsPerRx}
              minimumFractionDigits={1}
              maximumFractionDigits={1}
              showTotal={row.rowType === 'drug-total'}
            />
          },
          {
            property: 'marketShare',
            header: 'Market Share',
            align: 'end',
            render: (row) => <PercentCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.marketShare}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              showTotal={row.rowType === 'drug-total'}
            />
          },
          {
            property: 'totalAmount',
            header: 'Total Amount',
            align: 'end',
            render: (row) => <CurrencyCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.totalAmountReimbursed}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              singleState={bidAnalysisType === 'state'}
              isSuppressable={SUPPRESSABLE_PROPERTIES.includes('totalAmountReimbursed')}
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              isSuppressed={row.isSuppressionUsed || (row.rowType === 'drug' && row.suppressedValues.includes('totalAmountReimbursed'))}
              showTotal
            />
          },
          {
            property: 'totalWac',
            header: 'Total WAC',
            align: 'end',
            render: (row) => <CurrencyCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.totalWac}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              singleState={bidAnalysisType === 'state'}
              showTotal
            />
          },
          {
            property: 'pharmacyReimbursementUnit',
            header: 'Pharmacy Reimbursement (Unit)',
            align: 'end',
            render: (row) => {
              if (row.rowType !== 'ndc') {
                return (
                  <CurrencyCell
                    row={row}
                    // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                    value={row.pharmacyReimbursementUnit}
                    minimumFractionDigits={4}
                    maximumFractionDigits={4}
                    singleState={bidAnalysisType === 'state'}
                  />
                );
              }

              return (
                <PharmacyReimbursementUnitCell
                  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                  bidAnalysisId={bidAnalysis.id}
                  row={row}
                  singleState={bidAnalysisType === 'state'}
                  onSaveComplete={onRowUpdate}
                />
              );
            }
          },
          {
            property: 'totalPharmacyReimbursement',
            header: 'Total Pharmacy Reimbursement',
            align: 'end',
            render: (row) => <CurrencyCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.totalPharmacyReimbursement}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              singleState={bidAnalysisType === 'state'}
              showTotal
            />
          },
          {
            property: 'estimatedUra',
            header: 'Total URA',
            align: 'end',
            render: (row) => <CurrencyCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.totalUra}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              singleState={bidAnalysisType === 'state'}
              showTotal={row.rowType === 'drug-total'}
            />
          },
          {
            property: 'estimatedUraPercentWac',
            header: 'Est. URA %WAC',
            align: 'end',
            render: (row) => <PercentCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.estimatedUraPercentWac}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          },
          {
            property: 'brandGenericStatus',
            header: 'Brand/Generic Status',
            render: ({ rowType, brandGenericStatus }) =>
              (rowType === 'drug' || rowType === 'ndc') && (brandGenericStatus ?? <>&mdash;</>)
          },
          {
            property: 'acaFulUnitPrice',
            header: 'ACA-FUL Unit Price',
            align: 'end',
            render: (row) => <CurrencyCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.acaFulUnitPrice}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          },
          {
            property: 'acaWampUnitPrice',
            header: 'ACA-WAMP Unit Price',
            align: 'end',
            render: (row) => <CurrencyCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.acaWampUnitPrice}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          },
          {
            property: 'wacPerRx',
            header: 'WAC/Rx',
            align: 'end',
            render: (row) => <CurrencyCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.wacPerRx}
              minimumFractionDigits={4}
              maximumFractionDigits={4}
              singleState={bidAnalysisType === 'state'}
              showTotal={row.rowType === 'drug-total'}
            />
          },
          {
            property: 'pharmacyReimbursementPerRx',
            header: 'Pharm. Reim./Rx',
            align: 'end',
            render: (row) => <CurrencyCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.pharmacyReimbursementPerRx}
              minimumFractionDigits={4}
              maximumFractionDigits={4}
              showTotal={row.rowType === 'drug-total'}
            />
          },
          {
            property: 'estimatedUraPerRx',
            header: 'Est. URA/Rx',
            align: 'end',
            render: (row) => <CurrencyCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.estimatedUraPerRx}
              minimumFractionDigits={4}
              maximumFractionDigits={4}
              showTotal={row.rowType === 'drug-total'}
            />
          },
          {
            property: 'estimatedUroaPerRx',
            header: 'Est. UROA/Rx',
            align: 'end',
            render: (row) => <CurrencyCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.estimatedUroaPerRx}
              minimumFractionDigits={4}
              maximumFractionDigits={4}
              showTotal={row.rowType === 'drug-total'}
            />
          },
          {
            property: 'estimatedNetCostPerRx',
            header: 'Est. Net Cost/Rx',
            align: 'end',
            render: (row) => <CurrencyCell
              row={row}
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              value={row.estimatedNetCostPerRx}
              minimumFractionDigits={4}
              maximumFractionDigits={4}
              showTotal={row.rowType === 'drug-total'}
            />
          },
          {
            property: 'pdlStatus',
            header: 'PDL Status',
            render: (row) => {
              if (row.rowType !== 'ndc' && row.rowType !== 'drug' && row.rowType !== 'tag') {
                return null;
              }

              if ((row.rowType === 'ndc' || row.rowType === 'tag') && row.inheritedClinicalsInformation) {
                return <Text style={{ fontStyle: 'italic' }}>Inherited</Text>;
              }

              if (!row.coverages || row.coverages?.length === 0) {
                return <>&mdash;</>;
              }

              return (
                <Box gap="small">
                  {row.coverages.map(it => (
                    <PdlStatusCell key={it.stateProfileId} pdlStatus={it.pdlStatus}/>
                  ))}
                </Box>
              );
            }
          },
          {
            property: 'paCriteria',
            header: 'PA Criteria',
            render: (row) => {
              if (row.rowType !== 'ndc' && row.rowType !== 'drug') {
                return null;
              }

              if (!row.coverages || row.coverages?.length === 0) {
                return <>&mdash;</>;
              }

              return (
                <Box gap="small">
                  {row.coverages.map(it => {
                    if (
                      (!it.paTypes || it.paTypes.length === 0)
                      && !it.stepTherapyCount
                    ) {
                      return <span key={it.stateProfileId}>&mdash;</span>;
                    }

                    return (
                      <PaTypeCell
                        key={it.stateProfileId}
                        paTypes={it.paTypes}
                        stepTherapyCount={it.stepTherapyCount}
                        hasAdditionalSteps={it.hasAdditionalSteps}
                        format="shorthand"
                        type={it.paCriteriaDescription ? 'link' : 'text'}
                        onClick={() => onSelectCoverage(it, row)}
                      />
                    );
                  })}
                </Box>
              );
            },
          },
          {
            property: 'actions',
            sortable: false,
            size: 'small',
            render: (row) => {
              if (row.rowType !== 'drug' && row.rowType !== 'tag' && row.rowType !== 'ndc') return null;

              const urlSearchParams = new URLSearchParams({
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                stateName: row.state.name,
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                stateCode: row.state.code,
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                drugName: row.drug.label,
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                drugId: `${row.drug.id}`
              });

              return (
                <TextLink href={`/drug-coverage?${urlSearchParams.toString()}`} aria-label="Edit Coverage">
                  <HiOutlinePencil/>
                </TextLink>
              );
            }
          }
        ]}
        data={rows}
        rowProps={buildRowProps(rows)}
        primaryKey="key"
        background={{ body: { opacity: 100, color: 'light-1' }, header: { color: 'black' } }}
        pin
      />
    </div>
  );
};
