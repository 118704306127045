import { queryOptions, useQuery } from '@tanstack/react-query';

import { DrugCoverage } from '@/features/drugs/types/drug-coverage';
import { artiaProtectedApi } from '@/lib/api-client';
import { QueryConfig } from '@/lib/react-query';

const getDrugCoverages = (drugId: number): Promise<DrugCoverage[]> => {
  return artiaProtectedApi.get(`drugs/${drugId}/coverages`);
};

export const exportDrugCoveragesToCsv = (drugId: number) => {
  artiaProtectedApi.get(`drugs/${drugId}/coverages/export`, {
    headers: { 'Content-Type': 'text/csv' },
    responseType: 'blob'
  }).then((response: any) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', response.filename);
    document.body.appendChild(link);
    link.click();
  });
};

export const makeDrugCoveragesQueryKey = (drugId: number) => ['drug-coverages', drugId];

export const getDrugCoveragesOptions = (drugId: number) => {
  return queryOptions({
    queryKey: makeDrugCoveragesQueryKey(drugId),
    queryFn: () => getDrugCoverages(drugId)
  });
};

type UseDrugCoveragesOptions = {
  drugId: number;
  queryConfig?: QueryConfig<typeof getDrugCoveragesOptions>
};

export const useDrugCoverages = ({ drugId, queryConfig }: UseDrugCoveragesOptions) => {
  return useQuery({
    ...getDrugCoveragesOptions(drugId),
    ...queryConfig
  });
};

